#services-wrapper {
    padding: 5.44rem;
    @media (max-width:768px) {
    padding: 2rem;
    }
    @media (max-width:400px) {
    padding: 1rem;
           
        }

    h6 {
        color: #D6111E;
        font-family: Roboto;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        padding-bottom: 1.5rem;
    }

    h2 {
        color: #000;
        font-family: Roboto;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
    }
.services{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 4.62rem;

}
    .services-card {
        width: 12.375rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:nth-child(2){
            margin-top: 4rem;
            @media (max-width:768px) {
                margin-top: 0;

            }
        }

        span {
            width: 7.1875rem;
            height: 7.1875rem;
            border-radius: 50%;
            border: 1px solid red;
            display: flex;
            justify-content: center;
            align-items: center;
            
        }

        h3 {
            color: #D6111E;
            text-align: center;
            font-family: Roboto;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            padding: 2.19rem 0 1.25rem 0;
        }

        p {
            color: #000;
            text-align: center;
            font-family: Roboto;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-align: center;
        }
    }
}