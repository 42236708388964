#footer-wrapper {
    background: #5F050A;
    padding: 1.76rem 5.44rem;
    @media (max-width:768px) {
    padding: 1.76rem 2rem;
    }
    @media (max-width:400px) {
        padding: 1.76rem 1rem;
    }
    @media (max-width:350px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
         }

    p {
        color: #FFF;
        font-family: Roboto;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-top: 1.5rem;
    }
}