* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

body {
    font-family: 'Roboto', sans-serif;
}

// html {
//     font-size: 16px;

//     @media screen and (max-width: "1680px") {
//         font-size: 15px;
//     }

//     @media screen and (max-width: "1440px") {
//         font-size: 14px;
//     }

//     @media screen and (max-width: "1360px") {
//         font-size: 13px;
//     }

//     @media screen and (max-width: "1024px") {
//         font-size: 12px;
//     }

//     @media screen and (max-width: "992px") {
//         font-size: 11px;
//     }

//     @media screen and (max-width: "768px") {
//         font-size: 10px;
//     }

//     @media screen and (max-width: "600px") {
//         font-size: 9px;
//     }

//     @media screen and (max-width: "460px") {
//         font-size: 8px;
//     }
// }


button {
    padding: 1rem 3rem;
    border-radius: 0.625rem;
    background: linear-gradient(133deg, #5F050A 0%, #D6111E 100%);
    color: #FFF;
    font-family: Roboto;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border: none;
}

button:hover {
    background-color: #D6111E;
    box-shadow: 0px 15px 20px rgba(229, 46, 46, 0.4);
    color: #fff;
    transform: translateY(-7px);
}

button:active {
    transform: translateY(-1px);
}

.loaderWrapper {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}