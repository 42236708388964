#packages-wrapper {
    padding: 0rem 5.44rem 5.44rem 5.44rem;
    @media (max-width:768px) {
    padding: 0rem 2rem;
        
    }
    @media (max-width:400px) {
        padding: 0rem 1rem;
    }

    h2 {
        color: #000034;
        font-family: Roboto;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        padding-bottom: 1.56rem;
    }

    p {
        color: #D6111E;
        font-family: Roboto;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        // padding: 1.56rem 0 3.81rem 0;
        @media (max-width:1300px) {
           padding-bottom: 3rem; 
        }
        @media (max-width:400px) {
            padding-bottom: 1rem;
        }
    }

    .packages {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 3rem;
        @media (max-width:400px) {
           gap: 1rem;
        }


        .package-cards {
            border: 1px solid #D6111E;
            border-radius: 10px;
            padding: 2.31rem;
            width: 21.875rem;
            min-height: 31.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 2rem;
            transition: all 0.5s;
            &:nth-child(1), &:nth-child(3){
                margin-top: 5rem;
            }
            @media (max-width:1300px) {
                &:nth-child(1), &:nth-child(3){
                    margin-top: 0;
                }  
            }
            @media (max-width:400px) {
                padding:1.31rem;
            }

            // &:nth-child(1){
            //   .cards{
            //     ul{
            //         padding-bottom: 2rem;
            //     }
            //   }
            // }

            &:hover {
                box-shadow: 0px 0px 11px rgba(214, 17, 30, 0.25);
                border: 1px solid transparent;
                transform: scale(1.1);


            }

            .cards {
                h3 {
                    color: #D6111E;
                    font-family: Roboto;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding-bottom: 1.19rem;
                }

                P {
                    color: #000;
                    font-family: Roboto;
                    font-size: 0.625rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    text-align: left;
                }

                .mbps {
                    padding: 0.69rem 2.06rem;
                    border-radius: 0.375rem;
                    border: 1px solid #D6111E;
                    box-shadow: 0px 0px 4px 0px #D6111E;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 1.12rem 0;
                    @media (max-width:768px) {
                    padding: 0.69rem 1.06rem;
                        
                    }

                    p {
                        color: #D6111E;
                        font-family: Roboto;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding: 0;
                        margin: 0;
                    }

                    span {
                        width: 0.1rem;
                        height: 1.67613rem;
                        background: #D6111E;
                    }
                }

                ul {
                    padding: 0;
                    margin: 0;

                    li {
                        list-style: none;
                        display: flex;
                        gap: 0.94rem;
                        padding: 0.5rem 0;

                        svg {
                            width: 17px;
                            height: 16px;
                        }


                        p {
                            color: #000;
                            font-family: Roboto;
                            font-size: 0.75rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            padding: 0;
                            margin: 0;
                        }
                    }
                }

            }


            .order-wrapper {
                h4 {
                    color: #D6111E;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;


                    span {
                        color: #000034;
                        font-family: Roboto;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;

                    }
                }

                button {
                    width: 100%;
                }
            }

        }
    }
}