#popup-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    padding: 4.69rem;
    z-index: 10;

    @media (max-width:768px) {
    padding: 4.69rem 2rem;
    overflow: scroll;

    }
    @media (max-width:400px) {
        padding:5rem 0.5rem;
    }

    .popup {
        background: white;
        border-radius: 0.875rem;

        .icon {
            display: flex;
            align-items: center;
            justify-content: end;
           padding: 0.94rem;
            .popup-icon {
                position: relative;
                // border-radius: 3.125rem;
                // border: 1px solid rgba(15, 140, 209, 0.50);
                // background: rgba(213, 234, 250, 0.50);
                width: 2.3125rem;
                height: 2.3125rem;
                z-index: 1;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 1.5625rem;
                    height: 1.5625rem;
                }
            }
        }

    }
}