#billing-wrapper {
    padding: 0 5.44rem;

    @media (max-width:768px) {
        padding: 2rem;
    }

    @media (max-width:400px) {
        padding: 1rem;
    }

    .col-md-6 {
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        padding: 4rem 0;

        @media (max-width:768px) {
            padding: 0;
            align-items: center;

        }
    }

    .image {
        align-items: center !important;

        img {
            width: 100%;
        }
    }

    .content {
        padding-left: 2rem;

        @media (max-width:768px) {
            padding-left: 0.9rem;

        }
    }

    h2 {
        color: #D6111E;
        font-family: Roboto;
        font-size: 1.5625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    P {
        color: #000;
        font-family: Roboto;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 2.5rem 0;

        @media (max-width:768px) {
            padding: 1rem 0;
        }
    }

    button {}

}

.faq-title-custom {
    color: #000;
    font-family: Roboto;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding-bottom: 2.25rem;

    @media (max-width:768px) {
        padding: 1rem 0;
    }
}

.faq-define {
    color: #000;
    font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width:768px) {
        text-align: center;
    }

    @media (max-width:400px) {
        text-align: justify;
    }
}

.container.pt-3.pb-5.mob-padding {
    padding: 0 2rem;

    @media (max-width:400px) {
        padding: 0rem 1rem;
    }
}

#provider-contact-wrapper {
    padding: 3.81rem 3.81rem 0 3.81rem;

    @media (max-width:768px) {
        padding: 2rem 2rem 0rem 2rem;
    }

    @media (max-width:400px) {
        padding: 1rem 1rem 0rem 1rem;
    }

    .contact-us-show-mob {
        display: none;
        @media (max-width:768px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            h3 {
                color: #000034;
                text-align: center;
                font-family: Roboto;
                font-size: 1.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                span {
                    color: #CF101D;
                    font-family: Roboto;
                    font-size: 1.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            a {
                text-decoration: none;
                border-radius: 0.375rem;
                background: linear-gradient(357deg, #D6111E 0%, #66050B 100%);
                padding: 0.81rem 1.62rem;
                margin-top: 2.5rem;
                color: #FFF;
                font-family: Roboto;
                font-size: 1.34rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }

    .contact-us {
        @media (max-width:768px) {
            display: none;
        }
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h3 {
            color: #000034;
            text-align: center;
            font-family: Roboto;
            font-size: 1.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            span {
                color: #CF101D;
                font-family: Roboto;
                font-size: 1.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        a {
            text-decoration: none;
            border-radius: 0.375rem;
            background: linear-gradient(357deg, #D6111E 0%, #66050B 100%);
            padding: 0.81rem 1.62rem;
            margin-top: 2.5rem;
            color: #FFF;
            font-family: Roboto;
            font-size: 1.34rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    .connection-image {
        display: flex;

    }

    .connection-image-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;

        img {
            width: 50%;
        }

    }

    .show-mob {
        display: none;
    }

    @media (max-width:768px) {
        .hide-mob {
            display: none;
        }

        .show-mob {
            display: flex;
        }
    }



}

#contact-us {
    padding: 0rem 4rem 2rem;

    @media (max-width:768px) {
        padding: 2rem;
    }

    @media (max-width:400px) {
        padding: 1rem
    }

    .call-now {
        h2 {
            color: #D6111E;
        }

        p {
            width: 60%;
            margin: 2rem 0;

            @media (max-width:768px) {
                width: 100%;

            }

            @media (max-width:400px) {
                margin: 1rem 0rem;
            }
        }

        button {
            margin-bottom: 4rem;

            @media (max-width:400px) {
                margin-bottom: 2rem;
            }
        }
    }

    .form {
        input {
            width: 100%;
            margin-bottom: 2rem;
            border-radius: 0.375rem;
            border: 1px solid #D6111E;
            color: #D6111E;
            padding: 0.7rem;
            outline: none;

            &::placeholder {
                color: #D6111E;
            }

            @media (max-width:400px) {
                margin-bottom: 1rem;
            }
        }

        p {
            font-size: 0.7rem;
            margin-bottom: 2rem;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}